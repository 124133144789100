import Image from 'next/image';
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { ShoppingBannerItemProps } from './constants';
import RightShoppingBanner from './RightShoppingBanner';
import LeftShoppingBanner from './LeftShoppingBanner';


interface ShoppingBannerProps {
  banners: Array<ShoppingBannerItemProps>;
  brand_image: string;
  brand_image_alt: string;
  brand_image_style: string;
  bg_color: string;
  text_color: string;
  style: string;
  button_bg_color: string;
  button_text_color: string;
}

const ShoppingBanner = (props: ShoppingBannerProps) => {

  return (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      loop={true}
      slidesPerView={1}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="my-12 md:my-20"
    >
      {props.banners.map((banner, index) => (
        <SwiperSlide key={index}>
          {banner.style === "carousel" && (
            <Link href={banner.link}>
              <div className="md:hidden">
                <Image
                  src={banner.mobile_image}
                  alt={banner.image_alt}
                  quality={100}
                  width={1081}
                  height={1667}
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src={banner.desktop_image}
                  alt={banner.image_alt}
                  quality={100}
                  width={5834}
                  height={2400}
                />
              </div>
            </Link>
          )}
          {banner.style === "carousel_right" &&
            <RightShoppingBanner
              banner={banner}
              brand_image={props.brand_image}
              brand_image_alt={props.brand_image_alt}
              brand_image_style={props.brand_image_style}
              bg_color={props.bg_color}
              text_color={props.text_color}
              style={props.style}
              button_bg_color={props.button_bg_color}
              button_text_color={props.button_text_color}
            />}
          {banner.style === "carousel_left" &&
            <LeftShoppingBanner
              banner={banner}
              brand_image={props.brand_image}
              brand_image_alt={props.brand_image_alt}
              brand_image_style={props.brand_image_style}
              bg_color={props.bg_color}
              text_color={props.text_color}
              style={props.style}
              button_bg_color={props.button_bg_color}
              button_text_color={props.button_text_color}
            />}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ShoppingBanner
