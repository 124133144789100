import Image from 'next/image';
import Link from "next/link";
import { ShoppingBannerItemProps } from './constants';


interface LeftShoppingBannerProps {
  banner: ShoppingBannerItemProps;
  brand_image: string;
  brand_image_alt: string;
  brand_image_style: string;
  bg_color: string;
  text_color: string;
  style: string;
  button_bg_color: string;
  button_text_color: string;
}

const LeftShoppingBanner = (props: LeftShoppingBannerProps) => {

  return (
    <div className={`flex items-center w-full ${props.bg_color}`}>
      <Link href={props.banner.link}>
        <div className="relative hidden cursor-pointer md:block">
          <Image
            src={props.banner.desktop_image}
            alt={props.banner.image_alt}
            quality={100}
            width={2800}
            height={1152}
          />
          <div className="absolute -translate-y-1/2 top-1/2 left-20">
            {props.brand_image !== null && props.brand_image.length > 0 && (
              <div className={`flex ${props.style} ${props.brand_image_style}`}>
                <Image
                  src={props.brand_image}
                  alt={props.brand_image_alt}
                  width={1000}
                  height={500}
                  quality={100}
                  className="max-w-[177px]"
                />
              </div>
            )}
            <div className={`w-full ${props.text_color} text-[32px] font-dmsans mx-auto font-bold -tracking-[1.28px] capitalize`} dangerouslySetInnerHTML={{__html: props.banner.text}}></div>
            {props.banner.desc !== null && props.banner.desc !== undefined && props.banner.desc.length > 0 && (
              <div className={`w-full ${props.text_color} text-sm font-dmsans mx-auto font-normal mt-2`} dangerouslySetInnerHTML={{__html: props.banner.desc}}></div>
            )}
            <div className={`w-fit ${props.button_bg_color} ${props.button_text_color} p-4 mt-12 text-base font-medium`}>
              {props.banner.button_text}
            </div>
          </div>
        </div>
        <div className="relative cursor-pointer md:hidden">
          <Image
            src={props.banner.mobile_image}
            alt={props.banner.image_alt}
            quality={100}
            width={600}
            height={800}
          />
          <div className="absolute w-full bottom-8">
            <div className={`w-full ${props.text_color} text-2xl font-dmsans font-bold -tracking-[0.96px] text-center capitalize`} dangerouslySetInnerHTML={{__html: props.banner.text_mobile}}></div>
            {props.banner.desc !== null && props.banner.desc !== undefined && props.banner.desc.length > 0 && (
              <div className={`w-full ${props.text_color} text-sm font-dmsans font-normal mt-2 -tracking-[0.56px] text-center`} dangerouslySetInnerHTML={{__html: props.banner.desc_mobile}}></div>
            )}
            <div className={`w-fit ${props.button_bg_color} ${props.button_text_color} p-4 mx-auto mt-8 text-base font-medium`}>
              {props.banner.button_text}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default LeftShoppingBanner
