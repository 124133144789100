import Image from 'next/image';
import Link from "next/link";
import { InfoBannerItemProps } from './constants';


const RightInfoBanner = (props: InfoBannerItemProps) => {

  return (
    <div className={`flex items-center w-full ${props.bg_color}`}>
      <div className="w-1/2">
        <Image
          src={props.desktop_image}
          alt={props.image_alt}
          quality={100}
          width={900}
          height={815}
        />
      </div>
      <div className="w-1/2 text-center">
        <div className={`w-full ${props.text_color} text-[48px] font-dmsans mx-auto font-bold leading-normal`} dangerouslySetInnerHTML={{__html: props.text}}></div>
        {props.desc !== null && props.desc !== undefined && props.desc.length > 0 && (
          <div className={`w-full ${props.text_color} text-sm font-dmsans mx-auto font-normal mt-4`} dangerouslySetInnerHTML={{__html: props.desc}}></div>
        )}
        {props.link.indexOf(process.env.NEXT_PUBLIC_HOSTNAME!) !== -1 || props.link[0] === "/" ? (
          <Link href={props.link}>
            <div className={`w-fit ${props.button_bg_color} ${props.button_text_color} p-4 mx-auto mt-12 text-base font-medium`}>
              {props.button_text}
            </div>
          </Link>
        ) : (
          <Link href={props.link} rel="noopener noreferrer" target="_blank">
            <div className={`w-fit ${props.button_bg_color} ${props.button_text_color} p-4 mx-auto mt-12 text-base font-medium`}>
              {props.button_text}
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default RightInfoBanner
